<template>
  <div class="showcase" id="particle-canvas">
    <div id="showcase-content">
      <transition name="slide-fade">
        <h1 v-if="showText">
          <span>Helping you meet<br /> </span> your software and engineering
          needs.
        </h1>
      </transition>
      <transition name="slide-fade">
        <router-link class="nav-item" to="/services" v-if="showButton"
          >Learn More</router-link
        >
      </transition>
    </div>
  </div>
</template>

<script>
import config from "../../../vue.config.js";
export default {
  name: "Showcase",
  data() {
    return {
      showText: false,
      showButton: false,
    };
  },
  mounted() {
    let enviroment = process.env.VUE_APP_ENVIROMENT;
    let recaptchaScript = document.createElement("script");

    switch (enviroment) {
      case "development":
        recaptchaScript.setAttribute("src", "./js/particle.js");
        break;
      case "production":
        recaptchaScript.setAttribute(
          "src",
          `${config.publicPath}js/particle.js`
        );
        break;
      default:
        recaptchaScript.setAttribute("src", "./js/particle.js");
        break;
    }
    
    document.head.appendChild(recaptchaScript);
  },
  created() {
    setTimeout(() => {
      this.showText = true;
      this.showButton = true;
    }, 500);
  },
};
</script>

<style lang="scss">
.showcase {
  height: 90vh;
  width: 100%;
  position: relative;

  @media (min-width: 700px) {
    height: 70vh;
  }

  #showcase-content {
    width: fit-content;
    position: absolute;
    left: 20%;
    top: 35%;
    transform: translate(-20%, -50%);
    z-index: 500;
    padding: 0 2em;

    h1 {
      font-weight: 400;
      font-size: 1.9rem;

      @media (min-width: 1080px) {
        font-size: 2.2rem;
      }
    }

    span {
      color: $primary-accent;
    }

    a {
      background: transparent;
      border: 1px solid;
      padding: 0.75em 1em;
      margin-top: 1em;
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);
      color: $primary-text;
      font-size: 1rem;
      width: 50%;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      width: 200px;
      position: absolute;
      text-align: center;
      text-decoration: none;
    }

    @media (min-width: 1300px) {
      a {
        padding: 0.5em 1em;
        text-align: center;
        text-decoration: none;
        color: black;
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.5s;
  }

  .slide-fade-enter {
    opacity: 0;
    transform: translateX(20px);
  }
}
</style>
