<template>
  <div id="desktop-menu">
    <div class="nav-items">
      <router-link class="nav-item" to="/">Home</router-link>
      <router-link class="nav-item" to="/about">About Us</router-link>
      <router-link class="nav-item" to="/services">Services</router-link>
      <router-link class="nav-item" to="/portfolio">Portfolio</router-link>
      <!-- <router-link class="nav-item" to="/contact">Contact Us</router-link> -->
      <a class="nav-item" v-on:click="scrollToContact()">Contact Us</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopMenu",
  methods: {
    scrollToContact() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
#desktop-menu {
  display: none;
  position: absolute;
  right: 0;
  bottom: 5px;

  .nav-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;

    .nav-item {
      padding: 0 2em;

      &:nth-child(1) {
        border-right: 1px solid $primary-darkblue;
      }
      &:nth-child(2) {
        border-right: 1px solid $primary-darkblue;
      }
      &:nth-child(3) {
        border-right: 1px solid $primary-darkblue;
      }
      &:nth-child(4) {
        border-right: 1px solid $primary-darkblue;
      }
    }

    a {
      color: $primary-darkblue;
      text-decoration: none;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
      letter-spacing: 1px;
      font-size: 0.9rem;
    }
  }

  @media (min-width: 1080px) {
    display: block;
  }
}
</style>
