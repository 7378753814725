<template>
  <div id="app">
    <Header />
    <MobileMenu />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import MobileMenu from "@/components/layouts/MobileMenu.vue";
export default {
  components: { Header, Footer, MobileMenu },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $body-background;
  font-family: "Roboto", sans-serif;
  max-width: 1536px;
  margin: auto;
}

.shadow {
  box-shadow: 0 0 10px rgba(1, 1, 1, 0.25);
}

button,
input[type="submit"],
a {
  transition: 0.2s ease-out;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

h1 {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.container {
  padding: 6em 2em;
  margin: auto;
  max-width: 800px;

  p {
    line-height: 1.5;
    font-size: 0.9rem;
  }

  & > div {
    width: fit-content;

    .line {
      height: 2px;
      background-color: $primary-accent;
      margin-bottom: 0.5em;
    }
  }

  @media (min-width: 1080px) {
    h1 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
