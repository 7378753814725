<template>
  <div id="services-page" class="route-div">
    <div class="container">
      <div>
        <h1 class="container-title">Services</h1>
        <div class="line"></div>
      </div>

      <!-- engineering and consulting -->
      <div id="engineering-and-consulting" class="service-info-container">
        <div>
          <div class="service-title">
            <h2>Engineering and Consulting</h2>
            <p>
              We provide engineering and consulting services directly to
              customers, with a primary concentration in Probabilistic Risk
              Assessment.
            </p>
          </div>
          <ul class="service-details">
            <li>
              <h3>Probabilistic Risk Assessment</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse sed libero id purus lobortis mollis eget ac nisl.
                Suspendisse potenti. Ut faucibus vulputate mauris, eget
                facilisis lectus commodo non. Sed eget porttitor sem.
              </p>
            </li>
          </ul>
        </div>
        <img src="../../public/images/engineering.png" alt="" class="shadow" />
      </div>

      <!-- mobile and desktop development -->
      <div id="mobile-and-desktop-development" class="service-info-container">
        <div>
          <div class="service-title">
            <h2>Mobile and Desktop Development</h2>
            <p>
              We create custom cross-platform mobile applications for iOS and
              Android. We also develop desktop applications for Windows, macOS,
              and Linux systems.
            </p>
          </div>
          <ul class="service-details">
            <li>
              <h3>Mobile Applications</h3>
              <p>
                We use top-tier, native based frameworks that allow for fast and
                reliable development of iOS and Android applications with
                attractive user interfaces and desired user experiences. We
                cater to any custom based application that you need.
              </p>
            </li>
            <li>
              <h3>Desktop Applications</h3>
              <p>
                We develop high-end, native based desktop applications for
                Windows, macOS, and Linux operating systems. We use the latest
                technologies to ensure that your application is fast, reliable,
                and secure. Anywhere from simple applications to complex,
                system-wide applications. We are here to help you.
              </p>
            </li>
          </ul>
        </div>
        <img src="../../public/images/developer.png" alt="" class="shadow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services_Page",

  created() {
    setTimeout(() => {
      let titles = document.querySelectorAll(".container-title");
      for (let i in titles) {
        let lines = document.querySelectorAll(".line");

        lines[i].style.width = `${titles[i].clientWidth * 0.6}px`;
      }
    }, 300);

    setTimeout(() => {
      document.querySelector("footer").style.opacity = "1";
      if (window.innerWidth < 1080) return;

      let footerHeight = document.querySelector("footer").clientHeight;
      document.querySelector(
        ".route-div"
      ).style.marginBottom = `${footerHeight}px`;
    }, 1000);
  },
};
</script>

<style lang="scss">
#services-page {
  background-color: $body-background;
}

#services-page > div {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.service-info-container {
  margin-top: 2em;

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: center;
  }

  img {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: auto;
  }

  .service-title {
    h2 {
      font-size: 1.1rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-decoration: underline 1px $primary-accent;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.5;
      margin: 0.5em 0;
    }
  }

  .service-details {
    margin-top: 1em;

    li {
      margin-bottom: 1em;
      margin-left: 1em;

      h3 {
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 0.5em;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.5;
      }
    }
  }
}
</style>
