<template>
  <div class="member">
    <img :src="memberData['image']" alt="" />

    <div>
      <div>
        <h3 class="name">{{ memberData["name"] }}</h3>
        <h6 class="title">{{ memberData["title"] }}</h6>
      </div>
      <p class="description">{{ memberData["description"] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Member",
  props: ["memberData"],
};
</script>

<style lang="scss">
.member {
  margin: 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;

  @media (min-width: 800px) {
    flex-direction: row;
    max-width: 100%;

    & > div {
      padding: 2em;
    }
  }

  img {
    max-width: 100%;
    min-width: 200px;
    height: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  }

  & > div {
    margin-top: 1em;

    .name {
      text-decoration: underline 1px $primary-accent;
      font-size: 1.3rem;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .title {
      font-size: 0.8rem;
      font-weight: 500;
    }

    .description {
      margin-top: 1.5em;
    }
  }
}
</style>
