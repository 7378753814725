<template>
  <div id="services">
    <div class="engineering-container service-container">
      <div>
        <div>
          <font-awesome-icon icon="chart-bar" class="icon" />
          <h1>Engineering and Consulting</h1>
          <p>
            We provide engineering and consulting services directly to
            customers, with a primary concentration in Probabilistic Risk
            Assessment.
          </p>
        </div>
      </div>
    </div>
    <div class="developer-container service-container">
      <div>
        <div>
          <div>
            <font-awesome-icon icon="desktop" class="icon" />
            <font-awesome-icon icon="mobile-alt" class="icon" />
          </div>
          <h1>Mobile and Desktop Applications</h1>
          <p>
            We create custom cross-platform mobile applications for iOS and
            Android. We also develop desktop applications for Windows, macOS,
            and Linux systems.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>

<style lang="scss">
#services {
  width: 100%;
  background-color: $primary-darkblue;
  color: $body-background;
  text-align: center;

  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .service-container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &:nth-child(1) {
      background-image: url("../../../public/images/engineering.png");
      // background-position: 50% 30%;
    }

    &:nth-child(2) {
      background-image: url("../../../public/images/developer.png");
      background-position: 50% 0%;
    }

    & > div {
      padding: 4em 2em;
      background-color: rgba(0, 0, 0, 0.75);

      @media (min-width: 800px) {
        padding: 6em 2em;
      }

      & > div {
        margin: 0 auto;
        max-width: 500px;
      }

      h1 {
        font-size: 1.4rem;
        text-decoration: underline;
        font-weight: 400;
        margin-bottom: 0.4em;
      }

      p {
        font-size: 0.9rem;
        line-height: 1.5;
      }

      .icon {
        font-size: 2.5rem;
        margin-right: 0.25em;
        margin-bottom: 0.4em;
      }
    }
  }

  // & > div {
  //   max-width: 1300px;
  //   margin: auto;
  //   @media (min-width: 800px) {
  //     display: grid;
  //     grid-template-columns: 1fr 1fr;
  //     gap: 1em;
  //   }
  // }

  // .service-container {
  //   margin: 0 auto;
  //   max-width: 500px;
  //   background-position: center;
  //   background-size: cover;
  //   height: 100%;
  //   padding: 4em 0em;

  //   &:nth-child(1) {
  //     margin-bottom: 5em;
  //     background-image: url("../../assets/images/engineering.jpg");

  //     @media (min-width: 800px) {
  //       margin-bottom: 0;
  //     }
  //   }

  //   h1 {
  //     font-size: 1.4rem;
  //     text-decoration: underline;
  //     font-weight: 400;
  //     margin-bottom: 0.4em;
  //   }

  //   p {
  //     font-size: 0.9rem;
  //     line-height: 1.5;
  //   }

  //   .icon {
  //     font-size: 2.5rem;
  //     margin-right: 0.25em;
  //     margin-bottom: 0.4em;
  //   }
  // }
}
</style>
