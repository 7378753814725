module.exports = {
  publicPath:
    process.env.VUE_APP_ENVIROMENT === "production" ? "/craggconsulting/" : "/",
  css: {
    loaderOptions: {
      sass: {
        additionalData: `@import "@/assets/constants/colors.scss";`,
      },
    },
  },
};
