<template>
  <footer>
    <div class="inner-footer">
      <div class="top-container">
        <!-- CONTACT FORM -->
        <form
          ref="form"
          @submit.prevent="sendEmail"
          id="contact-form"
          v-if="!emailDone"
        >
          <h1>Contact Us</h1>
          <input
            type="email"
            name="email"
            v-model="email"
            placeholder="Email Address"
          />
          <textarea
            name="message"
            v-model="message"
            placeholder="Message"
          ></textarea>
          <input type="submit" value="Send" />
        </form>
        <div class="form-response" v-else>
          <h1>{{ emailResponse }}</h1>
        </div>

        <!-- CONTACT INFO -->
        <div class="info shadow">
          <div class="info-row">
            <font-awesome-icon icon="mobile" />
            <a href="tel:6822231150">682-223-1150</a>
          </div>
          <div class="info-row">
            <font-awesome-icon icon="map-marker-alt" />
            <h5>913 Spring Creek Drive, Grapevine, TX 76051</h5>
          </div>
          <div class="info-row">
            <font-awesome-icon icon="envelope" />
            <a href="mailto:chris@craggconsulting.net"
              >chris@craggconsulting.net</a
            >
          </div>
        </div>

        <!-- NAV ITEMS -->
        <div class="nav-list">
          <router-link class="nav-item" to="/">Home</router-link>
          <router-link class="nav-item" to="/about">About Us</router-link>
          <router-link class="nav-item" to="/services">Services</router-link>
          <router-link class="nav-item" to="/portfolio">Portfolio</router-link>
          <router-link class="nav-item" to="/contact">Contact Us</router-link>
        </div>
      </div>

      <!-- ADDITIONAL -->
      <div class="additional">
        <label>&copy; Cragg Consulting 2010-2022</label>
        <a
          href="https://www.privacypolicies.com/live/a755c642-e95f-4fb0-8e49-22b03ee6ac87"
          target="_blank"
          >Privacy Policy</a
        >
        <a
          href="https://www.termsfeed.com/live/d5e5df5e-bc76-4ffd-8800-b6a3dba796c9"
          target="_blank"
          >Terms of Service</a
        >
      </div>

      <!-- BADGE -->
      <div class="badge">
        <img
          src="../../../public/images/cc_logo_light.png"
          alt=""
        />
      </div>
    </div>
  </footer>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "Footer",
  data() {
    return {
      emailDone: false,
      emailResponse: "",
      email: "",
      message: "",
    };
  },
  methods: {
    sendEmail() {
      if (this.email === "" || this.message === "") return;

      emailjs
        .sendForm(
          "service_3u2umzd",
          "template_9oxkk9w",
          this.$refs.form,
          "user_r1UXgO1cHJYItOfUDBUy7"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.emailResponse = "Thank You For Contacting Cragg Consulting!";
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.emailResponse =
              "There was an error sending your message. Please try again.";
          }
        );

      this.emailDone = true;
      let formHeight = document.querySelector("#contact-form").offsetHeight;
      setTimeout(() => {
        document.querySelector(
          ".form-response"
        ).style.height = `${formHeight}px`;
        document.querySelector(".form-response").style.opacity = 1;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
footer {
  background-color: $primary-darkblue;
  color: $body-background;
  padding: 6em 2em 2em 2em;
  max-width: 1536px;
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0;

  .form-response {
    position: relative;
    padding: 2em;
    max-width: 500px;
    background-color: $seconday-darkgrey;
    color: $primary-text;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .inner-footer {
    max-width: 1080px;
    margin: 0 auto;
  }

  @media (min-width: 1080px) {
    z-index: -100;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #contact-form {
    display: grid;
    gap: 1em;
    max-width: 500px;
    width: 100%;
    position: relative;
    z-index: 100;

    input {
      color: $body-background;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    input:not([type="submit"]) {
      background: none;
      border: none;
      border-bottom: 1px solid $primary-accent;
      font-size: 0.9rem;
      padding: 0.5em 0 0.25em 0.25em;
      font-weight: 200;
      letter-spacing: 1px;
    }

    input[type="submit"] {
      background: none;
      border: 1px solid $primary-accent;
      padding: 0.5em 0;
      font-size: 1.1rem;
      border-radius: 2px;
      width: 200px;
      cursor: pointer;
    }

    textarea {
      background: none;
      border: 1px solid $primary-accent;
      border-radius: 2px;
      padding-left: 0.25em;
      padding-top: 0.25em;
      height: 200px;
      font-size: 0.9rem;
      color: $body-background;
      resize: none;
      font-family: "Roboto", sans-serif;
    }

    ::placeholder {
      color: $body-background;
      font-weight: 400;
      letter-spacing: 1px;
      color: rgba(248, 252, 255, 0.5);
    }
  }

  .nav-list {
    margin-top: 30px;
    max-width: 400px;
    position: relative;
    z-index: 100;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    @media (min-width: 1080px) {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }

    a {
      color: $body-background;
      letter-spacing: 2px;
      font-size: 0.8rem;
    }
  }

  .info {
    background-color: rgba(218, 219, 230, 0.5);
    padding: 2em;
    max-width: 500px;
    margin-top: 30px;
    position: relative;
    z-index: 100;

    .info-row:nth-child(1) svg {
      margin-left: 0.05em;
    }

    .info-row:nth-child(1) a {
      margin-left: 1.35em;
    }

    .info-row:nth-child(2) h5 {
      margin-left: 1.15em;
    }

    .info-row:nth-child(3) svg {
      margin-left: -0.1em;
    }

    .info-row {
      display: flex;
      margin-top: 1em;

      svg {
        color: $primary-accent;
        font-size: 1.7rem;
      }

      h5,
      a {
        margin-left: 1em;
        margin-top: 0.3em;
        text-decoration: none;
        color: $body-background;
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  .badge {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-bottom: 100%;

    img {
      transform: translateY(5%);
      width: 50%;
      height: 50%;
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.1;
    }
  }

  .additional {
    margin-top: 30px;
    position: relative;
    z-index: 100;

    label {
      color: $body-background;
      font-size: 0.7rem;
      letter-spacing: 1px;
    }

    a {
      color: $body-background;
      font-size: 0.7rem;
      letter-spacing: 1px;
      margin-left: 1em;
    }
  }
}
</style>
