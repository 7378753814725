<template>
  <div id="mobile-menu">
    <div class="nav-items">
      <router-link class="nav-item" to="/" @click.native="closeMenu"
        >Home</router-link
      >
      <router-link class="nav-item" to="/about" @click.native="closeMenu"
        >About Us</router-link
      >
      <router-link class="nav-item" to="/services" @click.native="closeMenu"
        >Services</router-link
      >
      <router-link class="nav-item" to="/portfolio" @click.native="closeMenu"
        >Portfolio</router-link
      >
      <!-- <router-link class="nav-item" to="/contact" @click.native="closeMenu"
        >Contact Us</router-link
      > -->
      <a
        class="nav-item"
        @click.native="closeMenu"
        v-on:click="scrollToContact()"
        >Contact Us</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  created() {
    setTimeout(() => {
      let headerHeight = document.querySelector("header").clientHeight;
      document.querySelector("#mobile-menu").style.top = `${headerHeight}px`;
    });

    document.addEventListener("scroll", () => {
      if (document.querySelector(".menu-button").classList.contains("open"))
        this.closeMenu();
    });
  },
  methods: {
    closeMenu() {
      document.querySelector("#mobile-menu").style.animation =
        "close 0.7s forwards";
      document.querySelector(".menu-button").classList.remove("open");
    },
    scrollToContact() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
#mobile-menu {
  width: 100%;
  height: 100vh;
  background-color: $primary-darkblue;
  color: $body-background;
  position: fixed;
  top: 60px;
  z-index: 2000;
  right: -350px;
  max-width: 350px;

  @media (min-width: 350px) {
    border-left: solid 1px $body-background;
  }

  .nav-item:nth-child(1) {
    border-top: solid 1px $body-background;
  }

  .nav-item {
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    background-color: $primary-darkblue;
    border-bottom: solid 1px $body-background;
    padding: 1em;
    display: grid;

    color: $body-background;
    text-decoration: none;

    &:hover {
      color: $primary-accent;
    }
  }

  .router-link-exact-active {
    opacity: 0.85;
    padding-left: 3em;
    color: $primary-text;
    position: relative;
    background-color: $seconday-darkgrey;

    &:after {
      content: "";
      position: absolute;
      width: 5px;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $primary-accent;
    }
  }
}

@keyframes open {
  from {
    right: -350px;
  }
  to {
    right: 0px;
  }
}

@keyframes close {
  from {
    right: 0px;
  }
  to {
    right: -350px;
  }
}
</style>
