<template>
  <div id="about">
    <div
      class="container"
      v-waypoint="{
        active: true,
        callback: onWaypoint,
        options: intersectionOptions,
      }"
    >
      <div>
        <h1 class="container-title">What We Do</h1>
        <div class="line"></div>
      </div>

      <p>
        We are a group of engineers who specialize in software development and
        perform custom based analysis for companies across different industries.
        Our main services include anywhere from custom mobile and desktop
        applications, to engineering and consulting. Currently, our main
        industry is the nuclear power industry, with expansion to rail,
        airlines, and aircraft manufacturers.
      </p>
      <br />
      <p>
        Our dedicated team is right there with you to guarantee top-tier
        experience, knowledge, and delivery for any service that you may need.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",

  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 1, // [0.25, 0.75] if you want a 25% offset!
      },
    };
  },
  methods: {
    onWaypoint({ going }) {
      // going: in, out
      // direction: top, right, bottom, left

      if (going === this.$waypointMap.GOING_IN) {
        setTimeout(() => {
          document.querySelector("#about .container").style.animation =
            "fadeInSlide 0.5s ease-out forwards";
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss">
#about {
  background-color: $primary-darkblue;
  color: $body-background;

  & > div {
    opacity: 0;
  }
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
