<template>
  <div id="home-page" class="route-div">
    <Showcase />
    <Services />
    <About />
    <Portfolio />
  </div>
</template>

<script>
// @ is an alias to /src
import Showcase from "@/components/home/Showcase.vue";
import About from "@/components/home/About.vue";
import Services from "@/components/home/Services.vue";
import Portfolio from "@/components/home/Portfolio.vue";

export default {
  name: "Home_Page",
  components: { Showcase, About, Services, Portfolio },
  created() {
    setTimeout(() => {
      document.querySelector("footer").style.opacity = "0";
    }, 100);

    setTimeout(() => {
      let titles = document.querySelectorAll(".container-title");
      for (let i in titles) {
        let lines = document.querySelectorAll(".line");

        lines[i].style.width = `${titles[i].clientWidth * 0.6}px`;
      }
    }, 300);

    setTimeout(() => {
      document.querySelector("footer").style.opacity = "1";
      if (window.innerWidth < 1080) return;

      let footerHeight = document.querySelector("footer").clientHeight;
      document.querySelector(
        ".route-div"
      ).style.marginBottom = `${footerHeight}px`;
    }, 1000);
  },
};
</script>
