import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartBar,
  faDesktop,
  faMobileAlt,
  faMobile,
  faMapMarkerAlt,
  faEnvelope,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueWaypoint from "vue-waypoint";
Vue.use(VueWaypoint);

library.add(faChartBar);
library.add(faDesktop);
library.add(faMobileAlt);
library.add(faMobile);
library.add(faMapMarkerAlt);
library.add(faEnvelope);
library.add(faTimes);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
