<template>
  <header>
    <div class="logo"></div>
    <button class="menu-button" v-on:click="toggleMenu">
      <div></div>
      <div></div>
      <div></div>
    </button>
    <DesktopMenu />
  </header>
</template>

<script>
import DesktopMenu from "@/components/layouts/DesktopMenu.vue";
export default {
  name: "Header",
  components: {
    DesktopMenu,
  },
  created() {
    document.addEventListener("scroll", () => {
      let verticalScrollOffset = window.pageYOffset;

      if (verticalScrollOffset > 0) {
        document.querySelector("header").classList.add("scrolled");
      } else {
        document.querySelector("header").classList.remove("scrolled");
      }
    });
  },
  methods: {
    toggleMenu() {
      let isOpen = document
        .querySelector(".menu-button")
        .classList.contains("open");
      if (!isOpen) {
        document.querySelector("#mobile-menu").style.animation =
          "open 0.7s forwards";
        document.querySelector(".menu-button").classList.add("open");
      } else {
        document.querySelector("#mobile-menu").style.animation =
          "close 0.7s forwards";
        document.querySelector(".menu-button").classList.remove("open");
      }
    },
  },
};
</script>

<style lang="scss">
.scrolled {
  background-color: $primary-darkblue;

  .logo {
    background-image: url("../../../public/images/cc_logo_light.png");
  }

  #desktop-menu {
    a {
      color: $body-background;
    }

    .nav-item {
      &:nth-child(1) {
        border-right: 1px solid $body-background;
      }
      &:nth-child(2) {
        border-right: 1px solid $body-background;
      }
      &:nth-child(3) {
        border-right: 1px solid $body-background;
      }
      &:nth-child(4) {
        border-right: 1px solid $body-background;
      }
    }
  }
}

header {
  background-color: $primary-darkblue;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;

  .logo {
    margin-left: 1em;
    background-image: url("../../../public/images/cc_logo_light.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 150px;
    height: 100px;
  }

  .menu-button {
    margin-right: 1.5em;
    background-color: transparent;
    border: none;
    cursor: pointer;

    div {
      height: 2px;
      margin-bottom: 5px;
      transition: all 0.5s ease-in-out;
    }

    div:nth-child(1) {
      width: 20px;
      background-color: $primary-accent;
    }

    div:nth-child(2) {
      width: 30px;
      background-color: $body-background;
    }

    div:nth-child(3) {
      width: 25px;
      background-color: $body-background;
    }

    // ANIMATION
    &.open div:nth-child(1) {
      width: 25px;
      background-color: $body-background;
      transform: rotate(45deg) translate(0px, 10px);
    }

    &.open div:nth-child(2) {
      transform: translateX(-20px);
      background: transparent;
    }

    &.open div:nth-child(3) {
      transform: rotate(-45deg) translate(0px, -10px);
    }
  }

  @media (min-width: 1080px) {
    background-color: $body-background;

    .logo {
      background-image: url("../../../public/images/cc_logo_dark.png");
    }

    .menu-button {
      display: none;
    }
  }
}
</style>
