<template>
  <div id="portfolio">
  <div class="container"  v-waypoint="{
      active: true,
      callback: onWaypoint,
      options: intersectionOptions,
    }">
    <div>
      <h1 class="container-title">Portfolio</h1>
      <div class="line"></div>
    </div>
    <div class="portfolio-content">
      <p>
        Our main area of service is the Nuclear Power industry. We are
        contracted developers to the Electric Power Research Institute (EPRI)
        and develop software for them to be used by the industry. We specialize
        in Risk and Reliability software.
      </p>
      <img src="../../../public/images/epri.png" alt="" />
    </div>
    <div class="portfolio-content">
      <p>
        We also service the health industry, specifically the Geriatric
        Psychology and Psychiatry community. We currently have a contract with
        Deer Oaks Behavioral Health in providing them with software used by
        their clinicians and the tools used by their administrative staff. These
        tools create the documentation and support all the necessary back-end
        processes.
      </p>
      <img src="../../../public/images/deer_oaks.png" alt="" />
    </div>
    <div class="portfolio-content">
      <p>
        We are the contracted support provider to Polestar, the marketing agent
        of CAFTA (developed by EPRI), providing customer support services, CAFTA
        training, and CAFTA environment extensions (filters and Addins).
      </p>
      <img src="../../../public/images/polestar.png" alt="" />
    </div>
    <div class="portfolio-content">
      <p>
        Lastly, we provide engineering services directly to customers. The
        primary area of concentration is Probabilistic Risk Assessment. We have
        supported many different clients including: 
        <ul>
            <li>The Tennessee Valley Authority</li>
            <li>Emirates Nuclear Engineering Corporation</li>
            <li>Florida Power and Light</li>
        </ul>
      </p>
      <div>
          <img src="../../../public/images/tva.jpg" alt="" />
          <img src="../../../public/images/emirates.jpg" alt="" />
          <img src="../../../public/images/fpl.jpg" alt="" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.4, // [0.25, 0.75] if you want a 25% offset!
      },
    };
  },

  methods: {
    onWaypoint({ going }) {
      // going: in, out
      // direction: top, right, bottom, left

      if (
        going === this.$waypointMap.GOING_IN
      ) {

        document.querySelector('#portfolio > div > div:nth-child(1)').style.animation = "fadeInRight 0.5s ease-in-out forwards";

        let images = document.querySelectorAll(".portfolio-content img");
        let paragraphs = document.querySelectorAll(".portfolio-content p");

        let i = 0;
        let animate = setInterval(() => {
           images[i].style.animation =
          "fadeInLeft 0.5s ease-in-out forwards";

          if (i == images.length - 1) {
            clearInterval(animate);
          }

          i++;
        }, 100);

      setTimeout(function() {
        
        for (let i in paragraphs) {
          paragraphs[i].style.animation =
          "fadeInRight 0.5s ease-in-out forwards";
        }
        
      }, 300);
      }

      
    },
  },
};
</script>

<style lang="scss">

#portfolio {
  background-color: $body-background;

  & > .container {
    & > div:nth-child(1) {
    opacity: 0;
  }
  }
  
}

.portfolio-content {
  margin-bottom: 2em;

  

  p {
    margin-bottom: 1em;
    opacity: 0;
  }

  ul {
      list-style-type:  square;
      padding-left: 1em;

      li {
          font-weight: 500;
      }
  }

  img {
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    height: 100px;
    object-fit: contain;
    opacity: 0;
  }

  @media (min-width: 660px) {
    &:not(&:nth-child(5)) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
      align-items: center;
    }

    &:nth-child(5) {
      &>div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;

        img:not(img:nth-child(2)) {
          object-fit: contain;
        }
       
      }
    }
  }

  
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
