import Vue from "vue";
import VueRouter from "vue-router";
import Home_Page from "../views/Home.vue";
import About_Page from "../views/About.vue";
import Services_Page from "../views/Services.vue";
import Portfolio_Page from "../views/Portfolio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home_Page",
    component: Home_Page,
  },
  {
    path: "/about",
    name: "About_Page",
    component: About_Page,
  },
  {
    path: "/services",
    name: "Services_Page",
    component: Services_Page,
  },
  {
    path: "/portfolio",
    name: "Portfolio_Page",
    component: Portfolio_Page,
  },
  {
    path: "*",
    redirect: { name: "Home_Page" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = "Cragg Consulting | " + to["name"].split("_")[0];

  next();
});

export default router;
